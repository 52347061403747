import React from "react";

import Page from "./Page";

export default function Projects() {
	return (
		<Page title="Projects">
			<h1>Projects</h1>
		</Page>
	);
}