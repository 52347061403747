import React from "react";

import Page from "./Page";

export default function NotFound() {
	return (
		<Page title="Not Found" description="It looks like you've taken a wrong turn!">
			<h1>404: Not Found</h1>
		</Page>
	);
}